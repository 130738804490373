$('form').submit(function(event) {
	event.preventDefault();
	$.ajax({
		type: "POST",
		url: "/mailer/smart.php",
		data: $(this).serialize()
	}).done(function(res) {
		$(this).find("input").val("");
		$('input').html(res);
		alert("Сообщение успешно отправлено");
		$("form").trigger("reset");
	});
	return false;
});